import { useState } from "react";
import CardButtons from "./CardButtons/CardButtons";

const NewspaperFeels = () => {
  // Dummy news data with varied content
  const allDummyArticles = [
    {
      id: 1,
      title: "Breaking News: Market Hits All-Time High",
      source_name: "Financial Daily",
      description:
        "The stock market reached an unprecedented high today, driven by positive economic data. This marks a significant milestone as investors continue to rally behind strong corporate earnings and government stimulus programs. Experts predict further growth in the coming months as global economies stabilize.",
      url: "#",
      image: "https://via.placeholder.com/300x200.png?text=Market+News",
    },
    {
      id: 2,
      title: "Sports Update: Local Team Wins Championship",
      source_name: "Sports Weekly",
      description:
        "The local team claimed victory in the championship game after a thrilling match that kept fans on the edge of their seats. This win is a culmination of their hard work and dedication throughout the season.",
      url: "#",
    },
    {
      id: 3,
      title: "Weather Alert: Heavy Rain Expected Tomorrow",
      source_name: "Weather Today",
      description:
        "Meteorologists are warning of heavy rainfall and possible flooding in the area. Residents are advised to take necessary precautions and stay updated with the latest weather advisories.",
      url: "#",
      image: "https://via.placeholder.com/300x400.png?text=Weather+Alert",
    },
    {
      id: 4,
      title: "Technology: New Smartphone Released",
      source_name: "Tech World",
      description:
        "A new smartphone model with cutting-edge features has hit the market, boasting a 120Hz display, advanced AI capabilities, and an innovative camera system designed to capture stunning photos in any condition.",
      url: "#",
    },
    {
      id: 5,
      title: "Health: Tips for a Balanced Diet",
      source_name: "Health Magazine",
      description:
        "Experts share tips on maintaining a balanced diet for a healthier lifestyle. Incorporating fresh fruits, vegetables, whole grains, and lean proteins into your meals can significantly improve your overall health.",
      url: "#",
    },
    {
      id: 6,
      title: "Travel: Top Destinations for 2025",
      source_name: "Travel Explorer",
      description:
        "Discover the most popular travel destinations for the upcoming year. From exotic beaches to bustling cities, these locations offer unforgettable experiences for every type of traveler.",
      url: "#",
      image: "https://via.placeholder.com/200x300.png?text=Travel+Destinations",
    },
    {
      id: 7,
      title: "Entertainment: Upcoming Movie Releases",
      source_name: "Cinema Times",
      description:
        "Check out the list of movies set to release this month, including highly anticipated blockbusters and indie gems. Don’t miss these cinematic experiences on the big screen.",
      url: "#",
    },
    {
      id: 8,
      title: "Science: Breakthrough in Renewable Energy",
      source_name: "Science Daily",
      description:
        "Researchers have made a breakthrough in renewable energy technology, developing a new system that harnesses solar power more efficiently. This innovation could revolutionize the energy sector.",
      url: "#",
      image: "https://via.placeholder.com/400x300.png?text=Renewable+Energy",
    },
    {
      id: 9,
      title: "Politics: New Policies Announced",
      source_name: "Daily Politics",
      description:
        "The government has announced a set of new policies aimed at boosting the economy and creating jobs. These policies are expected to have a significant impact on various sectors.",
      url: "#",
    },
    {
      id: 10,
      title: "Education: Advances in Online Learning",
      source_name: "Edu Times",
      description:
        "With the rise of online learning platforms, students now have access to quality education from anywhere in the world. Experts discuss the future of education in a digital age.",
      url: "#",
      image: "https://via.placeholder.com/350x250.png?text=Online+Learning",
    },
    {
      id: 11,
      title: "Economy: Inflation Rates Decline",
      source_name: "Business World",
      description:
        "Recent reports show a decline in inflation rates, bringing relief to consumers and businesses alike. Economists share insights on what this means for the future.",
      url: "#",
    },
    {
      id: 12,
      title: "Culture: Celebrating Local Traditions",
      source_name: "Cultural Digest",
      description:
        "Local communities are coming together to celebrate their unique traditions and heritage. These events highlight the importance of preserving cultural identity.",
      url: "#",
      image: "https://via.placeholder.com/300x300.png?text=Cultural+Events",
    },
    {
      id: 13,
      title: "Technology: Breakthrough in Quantum Computing",
      source_name: "Tech Innovations",
      description:
        "Quantum computing has taken a significant leap forward with the latest advancements in hardware and algorithms. This technology promises to solve problems previously thought unsolvable.",
      url: "#",
    },
    {
      id: 14,
      title: "Lifestyle: Minimalist Living Tips",
      source_name: "Lifestyle Guide",
      description:
        "Discover how minimalist living can simplify your life and improve your mental well-being. Experts share practical tips on decluttering and living intentionally.",
      url: "#",
    },
    {
      id: 15,
      title: "World News: Historic Peace Agreement Signed",
      source_name: "Global Times",
      description:
        "Leaders from two nations have signed a historic peace agreement, bringing an end to decades of conflict and paving the way for a new era of cooperation.",
      url: "#",
      image: "https://via.placeholder.com/300x400.png?text=Peace+Agreement",
    },
    {
      id: 16,
      title: "Science: Exploring the Depths of the Ocean",
      source_name: "Marine Science",
      description:
        "Researchers have discovered new marine species during their latest expedition to the depths of the ocean. These findings provide valuable insights into underwater ecosystems.",
      url: "#",
    },
  ];

  const [articles, setArticles] = useState(allDummyArticles.slice(0, 16));

  return (
    <div className="xxs:p-0 sm:p-4 mt-16 w-full flex justify-center items-center border-2 border-black">
      <div className="max-w-5xl">
        <h2 className="text-lg font-bold p-2 dark:text-white">
          Latest Articles
        </h2>
        <div
          className="grid gap-4 p-2"
          style={{
            gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
            // gridTemplateColumns: "repeat(2, 1fr)",
            gridAutoRows: "minmax(200px, auto)",
          }}
        >
          {articles.map((article) => (
            <div
              key={article.id}
              className="bg-white dark:bg-zinc-800 shadow-md rounded-md p-4 hover:shadow-lg transition-shadow duration-200"
              style={{
                gridRowEnd: `span ${article.image ? 2 : 1}`,
              }}
            >
              {article.image && (
                <img
                  src={article.image}
                  alt={article.title}
                  className="mb-2 rounded-lg w-full h-auto"
                />
              )}
              <h3 className="font-semibold text-lg mb-2 dark:text-white">
                {article.title}
              </h3>
              <p className="text-gray-700 dark:text-gray-400 text-sm mb-2">
                {article.description}
              </p>
              <p className="text-sm text-gray-600 dark:text-gray-300 mb-2">
                By - {article.source_name}
              </p>
              <CardButtons
                news={allDummyArticles}
                className="w-full flex justify-between my-2 dark:text-gray-600"
              />
              <a
                href={article.url}
                target="_blank"
                rel="noopener noreferrer"
                className="text-parewacreamcanprimary-200 bg-gray-100 dark:bg-zinc-700 rounded-lg p-1 px-3 text-sm"
              >
                Learn more
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default NewspaperFeels;
