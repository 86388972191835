import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useGetNewsByIdQuery } from "../services/api/newsServices.js";
import sourceImages from "../../utlis/sourceImages.js";
import { hitApi } from "../services/hitApi.js";
import { Eye } from "@styled-icons/fluentui-system-regular/Eye";
import { CalendarMonth } from "@styled-icons/fluentui-system-regular/CalendarMonth";
import { Time } from "@styled-icons/boxicons-regular/Time";
import { Facebook } from "@styled-icons/entypo-social/Facebook";
// import { TwitterSquare } from "@styled-icons/fa-brands/TwitterSquare";
import { Instagram } from "@styled-icons/boxicons-logos/Instagram";
// import Tags from "../Tags/Tags.jsx";
import { ToTitleCase } from "../HomePage/NewsCard/utils//ToTitleCase.js";
import CardButtons from "../HomePage/CardButtons/CardButtons.jsx";

const bucketUrl = process.env.REACT_APP_BUCKET_URL;
const glitchVideo = `${bucketUrl}/backend/assets/advertisement/glitch/GLITCH_Sidebar1.MP4`;
// const glitchVideo1 = `${bucketUrl}/backend/assets/advertisement/glitch/GLITCH_Sidebar.MP4`;
const glitchPNG = `${bucketUrl}/backend/assets/advertisement/glitch/THE FUSION BOMBER.PNG`;
const glitchPNGVideo = `${bucketUrl}/backend/assets/advertisement/glitch/The_Fusion_Bomber.MP4`;
// const glitchBanner = `${bucketUrl}/backend/assets/advertisement/glitch/GLITCH (1280 x 110 px).MP4`;

const NewsDetailPage = () => {
  const { newsId } = useParams();
  const [views, setViews] = useState(0);

  const { data } = useGetNewsByIdQuery(newsId);

  let news = data?.data;

  const image = news?.image_source;

  useEffect(() => {
    const fetchViews = async () => {
      try {
        const response = await hitApi.get(`/news/${newsId}/views`);
        setViews(response.data.views);
      } catch (error) {
        console.error("Error fetching views:", error.message);
      }
    };

    const updateViews = async () => {
      try {
        await hitApi.post(`/news/${newsId}/up-views`);
      } catch (error) {
        console.error("Error updating views:", error.message);
      }
    };

    fetchViews();
    updateViews();
  }, [newsId]);

  // const dummyNews = [
  //   {
  //     id: 1,
  //     title: "AP® Psychology - Course 5: Health and Behavior",
  //     description:
  //       "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Odio eligendi similique exercitationem optio libero vitae accusamus cupiditate laborum eos.",
  //     category: "psychology",
  //     source: "edx.org",
  //     source2: "classcentral.com",
  //   },
  //   {
  //     id: 2,
  //     title: "Introduction to Computer Science",
  //     description: "Learn the basics of computer science and programming.",
  //     category: "computer science",
  //     source: "coursera.org",
  //     source2: "udacity.com",
  //   },
  //   {
  //     id: 3,
  //     title: "Data Science and Machine Learning",
  //     description: "Explore the world of data science and machine learning.",
  //     category: "data science",
  //     source: "kaggle.com",
  //     source2: "datacamp.com",
  //   },
  //   {
  //     id: 4,
  //     title: "Digital Marketing Strategies",
  //     description:
  //       "Master the art of digital marketing with this comprehensive course.",
  //     category: "marketing",
  //     source: "hubspot.com",
  //     source2: "lynda.com",
  //   },
  //   {
  //     id: 5,
  //     title: "Financial Markets and Investment",
  //     description:
  //       "Understand the fundamentals of financial markets and investment strategies.",
  //     category: "finance",
  //     source: "investopedia.com",
  //     source2: "edx.org",
  //   },
  // ];

  const getDescription = (description) => {
    if (!description) return "";
    const parsedDescription =
      new DOMParser().parseFromString(description, "text/html").body
        .textContent + "…";
    return parsedDescription.split("[…]")[0];
  };

  const shareOnSocialMedia = (platform) => {
    const url = window.location.href;
    const text = `Check out this news: ${news?.title}`;
    let shareUrl = "";

    switch (platform) {
      case "facebook":
        shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${url}`;
        break;
      case "twitter":
        shareUrl = `https://twitter.com/intent/tweet?url=${url}&text=${text}`;
        break;
      case "instagram":
        // Instagram does not support direct sharing via URL
        shareUrl = `https://www.instagram.com/`;
        break;
      default:
        break;
    }

    window.open(shareUrl, "_blank");
  };

  return (
    <section className="xxs:mt-8 sm:mt-20 bg-white dark:bg-zinc-900 text-black dark:text-white w-full flex flex-col justify-center items-center">
      {/* Breadcrumbs */}
      <div className="w-full justify-start flex items-center max-w-7xl p-1 my-2">
        <nav aria-label="Breadcrumb">
          <ol className="flex items-center gap-1 text-sm text-black dark:text-white tracking-wide font-Comfortaa">
            <li>
              <a href="/home" className="block transition">
                <span className="sr-only"> Home </span>

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-5 h-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                  />
                </svg>
              </a>
            </li>

            <li className="rtl:rotate-180">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="size-4"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            </li>

            <li className="p-1">
              {news?.title
                ? new DOMParser().parseFromString(news?.title, "text/html").body
                    .textContent
                : ""}
            </li>
          </ol>
        </nav>
      </div>

      {/* News Detail */}
      <div className="w-full flex justify-center">
        <div className="flex flex-wrap xxs:flex-col sm:flex-row max-w-7xl">
          {/* Left Side */}
          <div className="w-full lg:w-8/12 relative">
            <div className="px-8 p-4 bg-zinc-100 dark:bg-zinc-800 dark:shadow-sm shadow-lg flex flex-col">
              {/* Title */}
              <h2 className="mb-6 mt-4 xxs:text-2xl sm:text-5xl font-bold leading-tight text-black dark:text-white sm:leading-tight">
                {news?.title
                  ? new DOMParser().parseFromString(news?.title, "text/html")
                      .body.textContent
                  : ""}
              </h2>

              {/* Views, Date, Time, Source and Type */}
              <div className="mb-5 flex flex-wrap items-center justify-between border-b pb-2 dark:border-white dark:border-opacity-10">
                <div className="flex flex-wrap flex-col gap-2">
                  {/* Views */}
                  <div className="absolute top-4 right-1 h-fit mr-3 flex items-center">
                    <p className="flex items-center text-base font-medium gap-1">
                      <span className="">
                        <Eye size={20} />
                      </span>
                      <span className="">{views}</span>
                    </p>
                  </div>
                  {/* Date, Time */}
                  <div className="flex items-center text-sm gap-2 font-medium h-full">
                    <span className="flex items-center gap-1 text-gray-500">
                      <CalendarMonth size={24} />
                      {new Date(news?.pub_date).toLocaleDateString()}
                    </span>

                    <span className="flex items-center gap-1 text-gray-500">
                      <Time size={24} />
                      {news?.source_name !== "Annapurna Post" &&
                        news?.source_name !== "Kathmandu Post" &&
                        news?.source_name !== "Ajako Artha" && (
                          <span className="">
                            {new Date(news?.pub_date).toLocaleTimeString(
                              "en-US",
                              {
                                hour: "2-digit",
                                minute: "2-digit",
                              }
                            )}
                          </span>
                        )}
                    </span>
                  </div>
                  {/* Source */}
                  <div className="flex items-center gap-1">
                    <h2 className="text-sm gap-[5px] flex font-Comfortaa w-full text-gray-500">
                      Published by
                      <span className="text-black dark:text-white tracking-wide">
                        {" "}
                        {news?.source_name}
                      </span>
                    </h2>
                    <div className="relative h-fit w-fit overflow-hidden rounded-lg">
                      <img
                        src={sourceImages[news?.source_name]}
                        alt={news?.source_name}
                        className="h-10 w-12 object-contain object-center"
                        fill
                      />
                    </div>
                  </div>
                </div>
                {/* Type */}
                <div className="xxs:mt-2 sm:mt-0 xxs:ml-1 sm:ml-0">
                  <p className="inline-flex items-center justify-center rounded-full bg-parewacreamcanprimary-100 dark:bg-zinc-900 py-2 px-4 text-sm font-semibold font-Comfortaa text-black dark:text-parewacreamcanprimary-100">
                    {ToTitleCase(news?.type || news?.category[0] || "Thinking")}
                  </p>
                </div>
              </div>

              {/* Image and Description and Continue Reading... and Share Post*/}
              <div className="p-2">
                {/* Image */}
                {news?.image_source && (
                  <div className="mb-5 w-full overflow-hidden rounded justify-center flex items-center ">
                    <div className="relative aspect-[97/60] justify-center w-full h-fit sm:aspect-[97/44]">
                      <img
                        src={image}
                        alt="image_body"
                        className="object-contain object-center w-full h-full"
                      />
                    </div>
                  </div>
                )}

                {/* <CardButtons
                  news={news}
                  className="flex w-full justify-evenly font-Comfortaa my-2"
                /> */}

                {/* Description */}
                <p className="mb-5 news-card-description text-base font-medium leading-relaxed text-black dark:text-white sm:text-lg sm:leading-relaxed lg:text-base lg:leading-relaxed xl:text-lg xl:leading-relaxed">
                  {getDescription(news?.description)}
                </p>
                {/* <div className="mb-10 w-full overflow-hidden rounded">
                    <div className="relative aspect-[97/60] w-full sm:aspect-[97/44]">
                      {/* <Image
                        src="/images/blog/blog-details-02.jpg"
                        alt="image"
                        fill
                        className="object-cover object-center"
                      /> 
                      <img src={image} alt="image_body" fill></img>
                    </div>
                  </div> */}

                {/* Continue Reading... */}
                <div className="relative z-10 mb-4 flex justify-center overflow-hidden rounded-lg bg-white hover:bg-opacity-50 dark:hover:bg-opacity-50 dark:bg-black">
                  <a
                    href={`${news?.source_url}`}
                    target="_blank"
                    rel="noreferrer"
                    className="text-center text-base font-medium italic w-full h-full dark:text-white text-black font-sans p-2"
                  >
                    Continue reading...
                  </a>
                </div>

                {/* Share Post */}
                <div className="items-center justify-between sm:flex">
                  {/* <Tags title={news?.title} description={news?.description} /> */}
                  <div className="flex items-center gap-2">
                    <h5 className="text-sm font-medium text-body-color sm:text-right">
                      Share this post :
                    </h5>
                    <div className="flex items-center sm:justify-end gap-1">
                      <button
                        onClick={() => shareOnSocialMedia("facebook")}
                        className="text-blue-600 hover:text-blue-800"
                      >
                        <Facebook size={24} />
                      </button>
                      {/* <button
                      onClick={() => shareOnSocialMedia("twitter")}
                      className="text-blue-400 hover:text-blue-600"
                    >
                      <TwitterSquare size={24} />
                    </button> */}
                      <button
                        onClick={() => shareOnSocialMedia("instagram")}
                        className="text-pink-600 hover:text-pink-800"
                      >
                        <Instagram size={26} />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Right Side  */}
          <div className="w-full lg:w-4/12 p-1 flex flex-col">
            {/* <h2 className="w-full">Advertisements</h2> */}
            <div className="w-fit h-full flex flex-col justify-between gap-2">
              <div
                onClick={() => {
                  window.open("https://www.instagram.com/glitch.np/", "_blank");
                }}
                className="w-full h-fit flex justify-center items-center cursor-pointer"
              >
                <img
                  src={glitchPNG}
                  alt="Ad"
                  className="w-[275px] h-[275px] object-contain"
                />
              </div>
              <div
                onClick={() => {
                  window.open("https://www.instagram.com/glitch.np/", "_blank");
                }}
                className="w-full h-fit flex justify-center items-center cursor-pointer"
              >
                <video
                  src={glitchVideo}
                  autoPlay
                  loop
                  muted
                  className="w-full h-[500px] object-contain"
                />
              </div>
              <div
                onClick={() => {
                  window.open("https://www.instagram.com/glitch.np/", "_blank");
                }}
                className="w-full h-fit flex justify-center items-center cursor-pointer"
              >
                <video
                  autoPlay
                  loop
                  muted
                  src={glitchPNGVideo}
                  alt="Ad"
                  className="w-[275px] h-[275px] object-cover"
                />
              </div>
              {/* <div className="w-fit h-fit flex justify-center items-center bg-zinc-200 dark:bg-zinc-800 rounded-lg mb-4">
              <video
                src={glitchVideo1}
                autoPlay
                loop
                muted
                className="w-full h-full object-cover"
              />
            </div>
            <div className="w-fit h-fit flex justify-center items-center bg-zinc-200 dark:bg-zinc-800 rounded-lg mb-4">
              <video
                src={glitchVideo}
                autoPlay
                loop
                muted
                className="w-full h-full object-cover"
              />
            </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NewsDetailPage;
