import { motion } from "framer-motion";
import { Filter } from "lucide-react";
import React, { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import "../../assets/output.css";
import sourceImages from "../../utlis/sourceImages.js";
import LoadingSkeleton from "../LoadingSkeleton.jsx";
import {
  useGetNewsPaginationQuery,
  useGetNewsWithImagesQuery,
} from "../services/api/newsServices.js";
import HeroSection from "./HeroSection/HeroSectionNew.jsx";
import MobileHeroSection from "./HeroSection/MobileHeroSection.jsx";
import LoginModal from "./Modals/LoginModal.jsx";
import SuccessfulAlert from "./Modals/SuccessfulAlert.jsx";
import NewsWithImage from "./NewsCard/NewsWithImage.js";
import NewsWithoutImage from "./NewsCard/NewsWithoutImage.js";
import useUser from "../../hooks/useUser.js";
import useToken from "../../hooks/useToken.js";
import { ToastContainer } from "react-toastify";

const bucketUrl = process.env.REACT_APP_BUCKET_URL;
const glitchVideo = `${bucketUrl}/backend/assets/advertisement/glitch/GLITCH_Sidebar1.MP4`;
const glitchVideo1 = `${bucketUrl}/backend/assets/advertisement/glitch/GLITCH_Sidebar.MP4`;
const glitchPNG = `${bucketUrl}/backend/assets/advertisement/glitch/THE FUSION BOMBER.PNG`;
const glitchBanner = `${bucketUrl}/backend/assets/advertisement/glitch/GLITCH_Banner.MP4`;
const parewaSoon = `${bucketUrl}/backend/assets/advertisement/parewa/Parewa_Soon.mp4`;

const HomePAGE = () => {
  const token = useToken();
  const [selectedSourceName, setSelectedSourceName] = useState("All");
  const [sortOption, setSortOption] = useState("default");
  const [activeButton, setActiveButton] = useState("All");
  const [finalNewsData, setFinalNewsData] = useState([]);
  const [showLoginAlert, setShowLoginAlert] = useState(false);
  const [buttonName, setButtonName] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  // const [newArticles, setNewArticles] = useState(false);
  // const [selectedFilter, setSelectedFilter] = useState("default");
  const showModal = useSelector((state) => state.auth.showModal);
  const [showPopupAd, setShowPopupAd] = useState(true);

  const userHook = useUser();

  const { user } = useMemo(
    () => (token ? { user: userHook?.user } : { user: null }),
    [userHook.user, token]
  );

  const userId = user?._id;

  let query = {
    page: currentPage,
    sortOption,
    userId, // Include userId in the query
  };

  if (selectedSourceName !== "All") {
    query.source_name = selectedSourceName;
  }

  let { isError, isSuccess, isLoading, data, error } =
    useGetNewsPaginationQuery(query);

  let {
    isError: isErrorImageQuery,
    // isSuccess: isSuccessImageQuery,
    // isLoading: isLoadingImageQuery,
    data: dataImageQuery,
    error: errorImageQuery,
  } = useGetNewsWithImagesQuery();

  // ---- NOTE ---- data.newsResults is the array of news!!
  let news = data?.newsResults || [];
  let totalPage = data?.totalPages || 0;

  let newsWithimage = dataImageQuery?.data || [];

  // Separate useEffect for resetting state
  useEffect(() => {
    setFinalNewsData([]);
    setCurrentPage(1);
  }, [selectedSourceName, sortOption]);

  useEffect(() => {
    if (isSuccess) {
      setFinalNewsData((prevNews) => {
        // If current page is 1 return the news that is fetched.
        if (currentPage === 1) {
          return news;
        }
        // Filter out duplicates
        const newNews = news.filter(
          (newArticle) =>
            !prevNews.some((prevArticle) => prevArticle._id === newArticle._id)
        );
        return [...prevNews, ...newNews];
      });
      setTotalPages(totalPage);
    }

    if (isError) {
      console.log("ERROR Occured! :( ", error?.error);
    }

    if (isErrorImageQuery) {
      console.log(
        "ERROR Occured fetching with Image! :( ",
        errorImageQuery?.errorImageQuery
      );
    }
  }, [
    isSuccess,
    isError,
    error,
    news,
    sortOption,
    selectedSourceName,
    // isSuccessImageQuery,
  ]);

  // Check for new articles
  // useEffect(() => {
  //   if (isSuccess && currentPage === 1) {
  //     setNewArticles(true);
  //   }
  // }, [isSuccess, currentPage]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowPopupAd(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  const handleSorting = (newSortOption) => {
    setSortOption(newSortOption);
  };

  const toggleSourceName = (sourceName) => {
    setSelectedSourceName(sourceName);
    setActiveButton(sourceName);

    // Scroll the selected news article into view
    const newsElement = document.getElementById(`news-${sourceName}`);
    if (newsElement) {
      newsElement.scrollIntoView({ behavior: "smooth", block: "center" });
    }

    // Scroll the corresponding button into view
    const buttonElement = document.getElementById(`button-${sourceName}`);
    if (buttonElement) {
      buttonElement.scrollIntoView({ behavior: "smooth", inline: "center" });
    }
  };

  // const handleFilterChange = (filter) => {
  //   setSelectedFilter(filter);
  //   handleSorting(filter);
  // };

  // const handleNewArticles = () => {
  //   setNewArticles(false);
  //   window.scrollTo({ top: 0, behavior: "smooth" });
  //   window.location.reload();
  // };

  const buttons = [
    { filter: "All", name: "All News", id: "source-all-news" },
    {
      filter: "Kathmandu Post",
      name: "Kathmandu Post",
      id: "source-kathmandu-post",
    },
    {
      filter: "Annapurna Post",
      name: "Annapurna Post",
      id: "source-annapurna-post",
    },
    { filter: "Nagarik News", name: "Nagarik News", id: "source-nagarik-news" },
    { filter: "Gorkhapatra", name: "Gorkhapatra", id: "source-gorkhapatra" },
    {
      filter: "Makalu Khabar Nepali",
      name: "Makalu Khabar Nepali",
      id: "source-makalu-khabar-nepali",
    },
    {
      filter: "Makalu Khabar English",
      name: "Makalu Khabar English",
      id: "source-makalu-khabar-english",
    },
    {
      filter: "Setopati Nepali",
      name: "Setopati Nepali",
      id: "source-setopati-nepali",
    },
    {
      filter: "Setopati English",
      name: "Setopati English",
      id: "source-setopati-english",
    },
    {
      filter: "Himalaya Times",
      name: "Himalaya Times",
      id: "source-himalaya-times",
    },
    {
      filter: "Karobar Daily",
      name: "Karobar Daily",
      id: "source-karobar-daily",
    },
    { filter: "Image Khabar", name: "Image Khabar", id: "source-image-khabar" },
    { filter: "Ratopati", name: "Ratopati", id: "source-ratopati" },
    {
      filter: "Online Khabar English",
      name: "Online Khabar English",
      id: "source-online-khabar-english",
    },
    {
      filter: "Online Khabar Nepali",
      name: "Online Khabar Nepali",
      id: "source-online-khabar-nepali",
    },
    // {
    //   filter: "The Himalayan Times",
    //   name: "The Himalayan Times",
    //   id: "source-the-himalayan-times",
    // },
    {
      filter: "Rajdhani Daily",
      name: "Rajdhani Daily",
      id: "source-rajdhani-daily",
    },
    { filter: "News 24", name: "News 24", id: "source-news-24" },
    { filter: "Techmandu", name: "Techmandu", id: "source-techmandu" },
    {
      filter: "News of Nepal",
      name: "News of Nepal",
      id: "source-news-of-nepal",
    },
    { filter: "Nepal News", name: "Nepal News", id: "source-nepal-news" },
    {
      filter: "The Rising Nepal",
      name: "The Rising Nepal",
      id: "source-the-rising-nepal",
    },
    // {
    //   filter: "Hamro Khelkud",
    //   name: "Hamro Khelkud",
    //   id: "source-hamro-khelkud",
    // },
    { filter: "Himal Khabar", name: "Himal Khabar", id: "source-himal-khabar" },
    { filter: "Drishti News", name: "Drishti News", id: "source-drishti-news" },
    {
      filter: "Reporters Nepal",
      name: "Reporters Nepal",
      id: "source-reporters-nepal",
    },
    { filter: "Khabarhub", name: "Khabarhub", id: "source-khabarhub" },
    { filter: "Ajako Artha", name: "Ajako Artha", id: "source-ajako-artha" },
    { filter: "BIZMANDU", name: "Bizmandu", id: "source-bizmandu" },
    { filter: "Clickmandu", name: "Clickmandu", id: "source-clickmandu" },
    { filter: "Baahrakhari", name: "Baahrakhari", id: "source-baahrakhari" },
    { filter: "Nepal Press", name: "Nepal Press", id: "source-nepal-press" },
    { filter: "Kendrabindu", name: "Kendrabindu", id: "source-kendrabindu" },
  ];

  buttons.sort((a, b) => a.name.localeCompare(b.name));

  return (
    <div>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        style={{ zIndex: 9999 }} // Ensure the toast is above other elements
      />
      {showPopupAd && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
          <div className="relative bg-white p-4 rounded-lg">
            <button
              onClick={() => setShowPopupAd(false)}
              className="absolute bottom-2 right-4 text-black font-Comfortaa text-sm tracking-wider bg-zinc-100 rounded-lg p-1 px-2"
            >
              Skip
            </button>
            <img src={glitchPNG} alt="Ad" className="w-full h-full" />
          </div>
        </div>
      )}
      {isLoading ? (
        <LoadingSkeleton />
      ) : (
        <div className="flex bg-white dark:bg-zinc-900 min-h-screen relative">
          {/* BODY */}
          <div className="justify-center items-center flex flex-col w-full">
            {/* Hero Section */}
            <div className="flex-col relative justify-center items-center w-full">
              {/* Feedback FORM */}
              <motion.div
                className="h-fit w-full xxs:mt-7 sm:mt-16  p-1 xxs:text-xs sm:text-sm dark:text-zinc-300 bg-white dark:bg-zinc-900 border-2 dark:border-b-2 dark:border-zinc-800 rounded-sm shadow-sm sm:justify-center xxs:block sm:flex gap-1 items-center"
                initial={{ y: -250 }}
                animate={{ y: 0 }}
                transition={{
                  delay: 0.2,
                  type: "spring",
                  stiffness: 120,
                  duration: 1.5,
                }}
              >
                We <span className="text-parewacreamcanprimary-200">value</span>{" "}
                your input! Please take a moment to share your thoughts in our
                survey -
                <a
                  href="https://forms.gle/w9sm1C2WLyzuLkBE8"
                  target="_blank"
                  rel="noreferrer"
                  className="text-xs text-blue-400 hover:underline ml-1"
                >
                  Click here to begin
                </a>
              </motion.div>
              {/* Feedback FORM */}

              {/* Banner */}
              <div className="w-full flex justify-center my-2">
                <div
                  onClick={() => {
                    window.open(
                      "https://www.instagram.com/glitch.np/",
                      "_blank"
                    );
                  }}
                  className="w-[1280px] h-fit bg-zinc-100 dark:bg-zinc-800 rounded-sm cursor-pointer"
                >
                  <video
                    src={glitchBanner}
                    autoPlay
                    loop
                    muted
                    // controls
                    className="max-w-full h-full object-contain"
                  />
                </div>
              </div>
              {/* Banner */}

              {/* HERO SECTION with 2 ads side by side */}
              <div className="h-fit flex gap-1 w-full items-center xxs:mt-10 xs:mt-0">
                {/* Ad 1 */}
                <div className="w-1/5 xxs:hidden xl:flex items-center">
                  {/* GLITCH */}
                  <div
                    onClick={() => {
                      window.open(
                        "https://www.instagram.com/glitch.np/",
                        "_blank"
                      );
                    }}
                    className="w-[300px] h-[600px] bg-zinc-200 dark:bg-zinc-800 rounded-sm cursor-pointer"
                  >
                    <video
                      src={glitchVideo1}
                      autoPlay
                      loop
                      muted
                      // controls
                      className="w-full h-full object-cover"
                    />
                  </div>
                  {/* GLITCH */}

                  {/* ANOTHER AD */}
                  {/* <div className="w-1/2 h-fit bg-zinc-200 dark:bg-zinc-800 rounded-lg">
                  <video
                    src={glitchVideo}
                    autoPlay
                    loop
                    muted
                    // controls
                    className="max-w-full max-h-full object-cover rounded-lg"
                  />
                </div> */}
                  {/* ANOTHER AD */}
                </div>
                {/* Latest News */}
                <div className="h-full w-full">
                  <h2 className="p-2 bg-white border-2 dark:bg-zinc-900 dark:shadow-xl dark:border-b-2 dark:border-zinc-800 dark:text-white tracking-wide font-Comfortaa shadow-md mb-2">
                    Latest News
                  </h2>
                  <MobileHeroSection newsWithimage={newsWithimage} />
                  <HeroSection newsWithimage={newsWithimage} />
                </div>
                {/* Ad 2 */}
                <div className="w-1/5 xxs:hidden xl:flex items-center">
                  {/* GLITCH */}
                  <div
                    onClick={() => {
                      window.open(
                        "https://www.instagram.com/glitch.np/",
                        "_blank"
                      );
                    }}
                    className="w-[300px] h-[600px] bg-zinc-200 dark:bg-zinc-800 rounded-sm cursor-pointer"
                  >
                    <video
                      src={glitchVideo}
                      autoPlay
                      loop
                      muted
                      // controls
                      className="max-w-full h-full object-cover"
                    />
                  </div>
                  {/* GLITCH */}

                  {/* ANOTHER AD */}
                  {/* <div className="w-1/2 h-fit bg-zinc-200 dark:bg-zinc-800 rounded-lg">
                  <video
                    src={glitchVideo}
                    autoPlay
                    loop
                    muted
                    // controls
                    className="max-w-full max-h-full object-cover rounded-lg"
                  />
                </div> */}
                  {/* ANOTHER AD */}
                </div>
              </div>
              {/* HERO SECTION with 2 ads side by side */}
            </div>

            {/* NEWS SECTION - LATEST ARTICLES */}
            <div className="flex-col -mt-8 justify-center items-center w-full">
              <div className="flex-col justify-center flex items-center w-full">
                {/* HEADING and BUTTONS */}
                <div className="flex flex-col px-2 w-full h-full mt-10">
                  {/* Heading */}
                  <div>
                    <h2 className="hidden sm:block p-1 xxs:text-lg xxs:uppercase lg:capitalize lg:text-2xl font-Comfortaa text-center my-2 text-black dark:text-zinc-500 xl:-mt-8">
                      Select a{" "}
                      <span className="text-parewacreamcanprimary-200">
                        news source
                      </span>{" "}
                      to explore Nepal's latest news
                    </h2>
                    <h2 className="block sm:hidden p-2 bg-white border-2 dark:bg-zinc-900 dark:shadow-xl dark:border-2 dark:border-zinc-800 dark:text-white tracking-wide rounded-lg font-Comfortaa shadow-md mb-2">
                      News Feed
                    </h2>
                  </div>
                  {/* Heading */}

                  {/* Buttons */}
                  <div className="flex-col items-center mb-5 p-3 shadow-md bg-white dark:bg-zinc-900 w-full">
                    {/* SORTING Option */}
                    <div className="flex items-center font-Comfortaa bg-zinc-200 dark:bg-zinc-800 rounded-lg w-fit">
                      <div className="p-2 border-r border-zinc-300 dark:border-black flex justify-center">
                        <Filter className="text-zinc-500 dark:text-parewacreamcanprimary-300 xxs:h-2/3 xxs:w-2/3 lg:h-full lg:w-full" />
                      </div>
                      <select
                        id="sort"
                        onChange={(e) => handleSorting(e.target.value)}
                        value={sortOption}
                        className="outline-none text-black xxs:text-sm lg:text-base dark:text-parewacreamcanprimary-200 p-1 hover:text-gray-500 transition duration-200 bg-zinc-200 dark:bg-zinc-800"
                      >
                        <option disabled>Select Filter</option>
                        <option value="default">By Default</option>
                        <option value="title">Title</option>
                        <option value="dateOldest">Date (Oldest First)</option>
                      </select>
                    </div>
                    {/* SORTING Option */}

                    {/* Source Name Buttons */}
                    <div className="w-full overflow-x-scroll gap-4 no-scrollbar flex py-2 dark:text-zinc-400 justify-start items-center">
                      {buttons.map((button) => {
                        return (
                          <button
                            key={button.filter}
                            id={`button-${button.filter}`} // Assign ID to the button
                            onClick={() => {
                              toggleSourceName(button.filter);
                            }}
                            className={`dark:hover:bg-zinc-800 hover:bg-zinc-300 px-2 p-1 h-fit text-center w-fit flex xxs:text-sm lg:text-base font-Comfortaa tracking-widee rounded-lg transition-all duration-300 ease-in-out ${
                              activeButton === button.filter
                                ? "bg-zinc-200 dark:bg-zinc-800 text-black dark:text-parewacreamcanprimary-200 rounded-lg px-2"
                                : ""
                            }`}
                          >
                            <span
                              id={button.id}
                              className="w-60 flex xxs:justify-start lg:justify-center items-center"
                            >
                              {sourceImages[button.name] && (
                                <img
                                  src={sourceImages[button.name]}
                                  alt={button.name}
                                  className="w-6 h-6 mr-2 rounded-full object-cover"
                                />
                              )}
                              {button.name}
                            </span>
                          </button>
                        );
                      })}
                    </div>
                    {/* Source Name Buttons */}
                  </div>
                  {/* Buttons */}
                </div>
                {/* BUTTONS */}

                {/* News Articles */}
                <div className="w-full flex xxs:flex-col sm:flex-row relative">
                  {/* Filters */}
                  {/* <div className="sm:w-fit xxs:w-full h-fit xxs:relative sm:sticky sm:top-20 sm:left-0">
                    <div className="w-full h-full font-Comfortaa flex-col flex gap-2 justify-center items-center">
                      <div className="flex items-center p-1 font-Comfortaa bg-white border-b-2 dark:bg-zinc-900 dark:border-zinc-800 rounded-sm w-full">
                        <div className="p-2 border-r border-zinc-300 dark:border-black flex justify-center">
                          <Filter className="text-zinc-500 dark:text-parewacreamcanprimary-300 xxs:h-2/3 xxs:w-2/3 lg:h-full lg:w-full" />
                        </div>
                        <div className="p-2 dark:text-white xxs:text-xs sm:text-sm">
                          <label className="flex items-center">
                            <input
                              type="radio"
                              name="filter"
                              checked={selectedFilter === "default"}
                              onChange={() => handleFilterChange("default")}
                              className="mr-2"
                            />
                            By Default
                          </label>
                          <label className="flex items-center mt-2">
                            <input
                              type="radio"
                              name="filter"
                              checked={selectedFilter === "title"}
                              onChange={() => handleFilterChange("title")}
                              className="mr-2"
                            />
                            Title
                          </label>
                          <label className="flex items-center mt-2">
                            <input
                              type="radio"
                              name="filter"
                              checked={selectedFilter === "dateOldest"}
                              onChange={() => handleFilterChange("dateOldest")}
                              className="mr-2"
                            />
                            Date (Oldest First)
                          </label>
                        </div>
                      </div>

                      <div className="flex flex-col gap-2">
                        <div
                          onClick={() => {
                            window.open(
                              "https://www.instagram.com/glitch.np/",
                              "_blank"
                            );
                          }}
                          className="w-[275px] h-fit flex justify-center items-center bg-zinc-200 dark:bg-zinc-800 cursor-pointer"
                        >
                          <img
                            src={glitchPNG}
                            alt="Ad"
                            className="w-full h-full object-cover"
                          />
                        </div>

                        <div
                          onClick={() => {
                            window.open(
                              "https://www.instagram.com/glitch.np/",
                              "_blank"
                            );
                          }}
                          className="w-[275px] h-fit flex justify-center items-center bg-zinc-200 dark:bg-zinc-800 cursor-pointer"
                        >
                          <video
                            autoPlay
                            loop
                            muted
                            src={glitchPNGVideo}
                            alt="Ad"
                            className="w-full h-full object-cover"
                          />
                        </div>
                      </div>
                    </div>
                  </div> */}

                  {/* NEWS CARDS */}
                  <div className="w-full h-full flex flex-col">
                    <div className="grid 2xl:grid-cols-4 xl:grid-cols-3 md:grid-cols-2 xxs:grid-cols-1 gap-4 font-sans h-full w-full px-2">
                      {isLoading ? (
                        <LoadingSkeleton />
                      ) : (
                        finalNewsData.map((item, index) => (
                          <div
                            key={item._id}
                            className="relative h-full w-full"
                          >
                            {/* {index === 2 && (
                              <div
                                onClick={() => {
                                  window.open(
                                    "https://www.instagram.com/glitch.np/",
                                    "_blank"
                                  );
                                }}
                                className="w-[275px] h-fit flex justify-center items-center bg-zinc-200 dark:bg-zinc-800 rounded-lg cursor-pointer"
                              >
                                <img
                                  src={glitchPNG}
                                  alt="Ad"
                                  className="w-full h-full object-cover rounded-lg"
                                />
                              </div>
                            )}

                            {index === 8 && (
                              <div
                                onClick={() => {
                                  window.open(
                                    "https://www.instagram.com/glitch.np/",
                                    "_blank"
                                  );
                                }}
                                className="w-[275px] h-fit flex justify-center items-center bg-zinc-200 dark:bg-zinc-800 rounded-lg cursor-pointer"
                              >
                                <video
                                  autoPlay
                                  loop
                                  muted
                                  src={glitchPNGVideo}
                                  alt="Ad"
                                  className="w-full h-full object-cover rounded-lg"
                                />
                              </div>
                            )} */}

                            {/* {index === 5 && (
                            <div className="w-1/2 h-full flex justify-center items-center bg-zinc-200 dark:bg-zinc-800 rounded-lg">
                              <video
                                src={glitchVideo}
                                autoPlay
                                loop
                                muted
                                // controls
                                className="max-w-full max-h-full object-cover rounded-lg"
                              />
                            </div>
                          )} */}

                            {item.source_name === "Himalaya Times" ||
                            item.source_name === "Ratopati" ||
                            item.source_name === "Ajako Artha" ||
                            !item.image_source ? (
                              <NewsWithoutImage
                                key={item._id}
                                news={item}
                                setShowLoginAlert={setShowLoginAlert}
                                setButtonName={setButtonName}
                                buttonName={buttonName}
                                toggleSourceName={toggleSourceName}
                              />
                            ) : (
                              <NewsWithImage
                                key={item._id}
                                news={item}
                                setShowLoginAlert={setShowLoginAlert}
                                setButtonName={setButtonName}
                                buttonName={buttonName}
                                toggleSourceName={toggleSourceName}
                              />
                            )}
                          </div>
                        ))
                      )}
                    </div>
                    {/* Show More Button */}
                    {currentPage < totalPages && (
                      <div className="w-full mt-4 p-2 mb-2 rounded-lg font-Comfortaa">
                        <div className="flex justify-center">
                          <button
                            onClick={() => setCurrentPage((prev) => prev + 1)}
                            className="px-4 py-2 bg-zinc-100 dark:bg-zinc-900 bg-gradient-to-t from-zinc-100 dark:from-zinc-800 to-zinc-50 dark:to-zinc-900 shadow-lg text-black dark:text-white rounded-full hover:bg-opacity-50 hover:text-opacity-25 dark:hover:bg-opacity-50 dark:hover:text-opacity-25"
                          >
                            Show More
                          </button>
                        </div>
                      </div>
                    )}

                    {/* Banner */}
                    <div className="w-full flex justify-center my-2">
                      <div
                        onClick={() => {
                          window.open(
                            "https://www.instagram.com/parewaainc/",
                            "_blank"
                          );
                        }}
                        className="w-[1280px] h-fit bg-zinc-100 dark:bg-zinc-800 cursor-pointer"
                      >
                        <video
                          src={parewaSoon}
                          autoPlay
                          loop
                          muted
                          // controls
                          className="max-w-full h-full object-contain xxs:rounded-none xl:rounded-lg"
                        />
                      </div>
                    </div>
                    {/* Banner */}
                  </div>
                </div>
              </div>
            </div>
            {/* NEWS SECTION - LATEST ARTICLES */}
          </div>
          {/* BODY */}

          {showModal && (
            <div className="fixed top-auto left-auto inset-2 z-50 max-h-fit max-w-sm">
              <SuccessfulAlert buttonName={buttonName} />
            </div>
          )}

          {showLoginAlert && (
            <div className="fixed inset-2 z-50 max-h-full  rounded-lg max-w-screen bg-zinc-100 dark:bg-zinc-900 dark:bg-opacity-90 bg-opacity-80 font-Comfortaa flex items-center justify-center">
              <div className="h-fit flex flex-col items-center bg-parewaBackground bg-no-repeat bg-left">
                <div className="flex p-6 w-full justify-center bg-zinc-100 dark:bg-black dark:bg-opacity-80 text-black dark:text-white shadow-md mb-2 rounded-lg">
                  You are about to{" "}
                  <span className="text-parewacreamcanprimary-200 mr-1 ml-1">
                    {buttonName}
                  </span>{" "}
                  a news article
                </div>
                <LoginModal setShowLoginAlert={setShowLoginAlert} />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default HomePAGE;
